import React from 'react'
import {
  Typography,
  Grid,
  TextField,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  LinearProgress,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Alert } from 'react-bootstrap'
import { fetchNewsletterData } from '../../lib/global/newsletter_api'
import { useTranslation } from 'react-i18next'
import { validateMailaddress } from '../../utils/utils'

const NewsletterForm = (props: any) => {
  const [mailAddress, setMailAddress] = React.useState('')
  const [terms, setTerms] = React.useState(false)
  const [validMail, setValidMail] = React.useState(false)
  const [validTerms, setValidTerms] = React.useState(false)
  const [info, setInfo] = React.useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation('translation')

  const handleChange = (event: any) => {
    let tf: any = event.target
    let m = tf.value.trim()

    setMailAddress(m)
    setValidMail(validateMailaddress(m))
  }

  const handleTerm = (event: any) => {
    let tf: any = event.target
    setTerms(tf.checked)
    setValidTerms(tf.checked)
  }

  const handleSubscribe = (event: any) => {
    setInfo('')
    setError('')
    setLoading(true)

    fetchNewsletterData({ mailAddress: mailAddress, mode: 'subscribe' }).then(
      (response) => {
        if (response.error > 0) {
          setLoading(false)
          setError(t('Die Anmeldung konnte nicht durchgeführt werden.'))
        } else {
          setLoading(false)
          setInfo(t('Alles klar!'))
        }
      },
    )
  }

  const handleUnsubscribe = (event: any) => {
    setInfo('')
    setError('')
    setLoading(true)

    fetchNewsletterData({ mailAddress: mailAddress, mode: 'unsubscribe' }).then(
      (response) => {
        if (response.error > 0) {
          setLoading(false)
          setError(
            t(
              'Die Abmeldung konnte nicht durchgeführt werden. Bitte wende dich an service@artboxone.de',
            ),
          )
        } else {
          setLoading(false)
          setInfo(t('Die Abmeldung wurde durchgeführt'))
        }
      },
    )
  }

  return (
    <React.Fragment>
      <Typography variant="h6">{t('Newsletter-Einstellungen')}</Typography>

      <Typography gutterBottom>
        {t('Melde dich jetzt für unseren Newsletter an.')}
      </Typography>

      {error != '' && (
        <Alert key="newsletter_error" variant="danger">
          {error}
        </Alert>
      )}

      {info != '' && (
        <Alert key="newsletter_info" variant="success">
          {info}
        </Alert>
      )}

      {loading == true && <LinearProgress />}

      {info == '' && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                name="mail_address"
                label={t('Email-Adresse')}
                fullWidth
                autoComplete="mail"
                value={mailAddress}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="terms2"
                  value="yes"
                  onClick={handleTerm}
                  checked={terms}
                />
              }
              label={t(
                'Ich habe die Datenschutzerklärung gelesen und stimme ausdrücklich zu.',
              )}
            />
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubscribe}
            disabled={!(validMail && validTerms) || loading}
          >
            <AddIcon /> {t('Für Newsletter anmelden')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUnsubscribe}
            disabled={!(validMail && validTerms) || loading}
          >
            <RemoveIcon /> {t('Vom Newsletter abmelden')}
          </Button>
        </>
      )}

      <Box paddingBottom={4} />
    </React.Fragment>
  )
}

export default NewsletterForm
