import scrollTo from 'gatsby-plugin-smoothscroll'
import React from 'react'
import Layout from '../components/layout/Layout'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ProductsOverview from '../components/products/ProductsOverview'
import BundleOverview from '../components/products/BundleOverview'
import SEO from '../components/generic/Seo'
import processString from 'react-process-string'
import SearchField from '../components/products/Searchfield'
import Breadcrumb from './../components/artboxone/Breadcrumb'
import { AlternateUrl, BreadcrumbLayer , Rating } from '../types'
import Button from '@mui/material/Button'
import Edit from '../components/cms/Edit'
import LandingPagePues from '../components/products/LandingPagePues'
import NewsletterForm from './forms/NewsletterForm'
import InfoIcon from '@mui/icons-material/Info'
import {env} from '../../environment'
import BuyBox from '../components/products/BuyBox'
import {
  setEnvironmentVariable,
  pushTmEvent,
} from '../components/layout/EnvironmentVariables'
import HrefButton from '../components/generic/HrefButton'
import { withUrlSlugs } from '../components/generic/withUrlSlugs'
import { buildAlternativeUrlsForLps } from '../utils/UrlUtils'
import LandingPageMotifList from '../components/products/LandingPageMotifList'
import { getHash } from '../utils/Crypto'
import BreakElement from '../components/products/BreakElement'
import { extractSub } from './upselling/helper'
import FeaturedImage from '../components/featured-image/FeaturedImage'
import CustomerReviews from '../components/reviews/CustomerReviews'
import TransformData from '../components/reviews/helpers/transformData'
import StarRating from '@pixum/rating'
import getRatingsData from '../utils/RatingsUtils'

export interface LandingPageOptions {
  data: any
  classes: any
  pageContext: any
  path?: string
  page: number
}

const Landingpage = (options: LandingPageOptions) => {
  const pageData = options.pageContext.pageData || {}
  const landingPageCrossUrls =
    typeof options.data.allLandingpageUrl.nodes[0] == 'object'
      ? options.data.allLandingpageUrl.nodes[0].urls
      : { de: '', en: '', ch: '', da: '' }

  const landingPageData = options.data.allCockpitLandingpages.nodes[0]

  const teaser = options.data.allMarkdownRemark.nodes.filter(
    (node: { id: string }) => node.id === options.pageContext.teaser,
  )[0]
  const description = options.data.allMarkdownRemark.nodes.filter(
    (node: { id: string }) => node.id === options.pageContext.description,
  )[0]
  const ratingsData = getRatingsData(landingPageData, options.pageContext)
  const handleKVRatingClick = () => scrollTo('#customerReviews', 'center')

  const page = options.page || 1

  const config = [
    // %%bundle:poster:1234%%
    {
      regex: /%%bundle:([a-z_]+):([0-9]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <ProductsOverview bundleId={result[2]} key={key} material={result[1]} />
      ),
    },
    {
      regex: /<hr>/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <BreakElement key={key} />
      ),
    },
    // %%buy:poster:1234%%
    {
      regex: /%%buy:([a-z_]+):([0-9]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <BuyBox key={key} material={result[1]} motiveIDs={result[2]} />
      ),
    },

    // %%products:poster-28178;51728;47509;plastic_frame:45452;47541;58169%%
    {
      regex: /%%products:([^%]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <LandingPageMotifList config={result[1]} display={pageData[result[1]]} key={key} slider={false}/>
      ),
    },

    // %%slider:poster-28178;51728;47509;plastic_frame:45452;47541;58169%%
    {
      regex: /%%slider:([^%]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <LandingPageMotifList config={result[1]} display={pageData[result[1]]} key={key} slider={true}/>
      ),
    },

    // %%bundle:poster:fotographie_querformat_reise:katze%%
    {
      regex: /%%bundle:([^:]+):([^:]+):([^%]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <LandingPagePues
          base_url={options.pageContext.base_url}
          key={key}
          material={result[1]}
          page={page}
          searchQuery={result[3]}
          urlfilter={result[2].split(' ').join('_')}
        />
      ),
    },
    // %%bundle:poster:fotographie_querformat_reise%%
    {
      regex: /%%bundle:([^:]+):([^:]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <LandingPagePues
          base_url={options.pageContext.base_url}
          key={key}
          material={result[1]}
          page={page}
          searchQuery={''}
          urlfilter={result[2].split(' ').join('_')}
        />
      ),
    },
    // %%bundle:1234%%
    {
      regex: /%%bundle:([0-9]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <BundleOverview
          base_url={options.pageContext.base_url}
          bundleId={result[1]}
          key={key}
          page={page}
        />
      ),
    },
    {
      regex: /%%button:([^:]+):([^%]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <HrefButton
          fullWidth={false}
          href={result[2]}
          key={key}
          text={result[1]}
        />
      ),
    },
    {
      regex: /%%infobutton:([^:]+):([^%]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <Button
          className="cta_lp_button"
          color="secondary"
          fullWidth={false}
          href={result[2]}
          key={key}
          variant="contained"
        >
          <InfoIcon /> {result[1]}
        </Button>
      ),
    },
    {
      regex: /%%datenschutz:([^%]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <Button
          className="cta_lp_button"
          color="secondary"
          fullWidth={false}
          key={key}
          onClick={() => {
            if (typeof UC_UI == 'undefined') {
              window.alert(
                'Tracking nicht initialisiert. Bitte Seite neu laden.',
              )
            } else {
              UC_UI.showSecondLayer()
            }
          }}
          variant="contained"
        >
          <InfoIcon /> {result[1]}
        </Button>
      ),
    },
    {
      regex: /%%secbutton:([^:]+):([^%]+)%%/gim,
      fn: (key: string | number | undefined, result: any[]) => (
        <Button
          className="cta_lp_button"
          color="secondary"
          fullWidth={false}
          href={result[2]}
          key={key}
          variant="contained"
        >
          {result[1]}
        </Button>
      ),
    },
    {
      regex: /%%searchbutton%%/gim,
      fn: (key: any, result: any[]) => (
        <SearchField isOnProductsPage={false} showSubmitButton={true} />
      ),
    },
    {
      regex: /%%newsletterform%%/gim,
      fn: (key: any, result: any[]) => <NewsletterForm />,
    },
    {
      regex: /%%ratings%%/gim,
      fn: () => {
       if (ratingsData.showRatingContent) {
         return (<a
           className="rating-stars__ratings-link"
           title={ratingsData.customerFeedback}
         >
           <StarRating
             {...(ratingsData.rating as Rating)}
             description={ratingsData.ratingDescription}
             onClick={handleKVRatingClick}
           />
         </a>)
       } else {
         return <></>
       }
      }
    },
  ]

  const code = description.html
  // let code = '<hr>%%buy:poster_plastic:28178%%<hr>'
  // code = "<hr> %%products:\n"+
  // "wallcalendar:2020;\n" +
  // "poster_plastic:58169;\n"+
  // "72059;\n"+
  // "%% <hr>"

  const html: any = extractSub(processString(config)(code))
  const teaserHtml: any = extractSub(processString(config)(teaser.html))
  const breadcrumbLayers: BreadcrumbLayer[] = []

  if (typeof options.pageContext.base_url === 'string') {
    const path = options.pageContext.base_url.slice(1).split('/')
    let breadcrumbUrl = ''
    path.map(elem => {
      breadcrumbUrl += '/' + elem
      breadcrumbLayers.push({
        url: breadcrumbUrl,
        name: elem.toUpperCase(),
      })
    })
  }

  const title = '' + landingPageData.pagetitle?.value

  if (breadcrumbLayers.length > 1) {
    breadcrumbLayers.pop()

    breadcrumbLayers.push({
      url: '',
      name: title,
    })
  }

  // GTM PUSH EVENT
  // window.dataLayer?.push({ event: `showTest` })
  const seoTitle = title
  const featuredImgUrl = landingPageData.featured_image_url?.value || undefined
  const featuredImgTitle = landingPageData.featured_image_alt?.value || ''

  const showFeaturedImageOnDesktop = landingPageData.show_kv_desktop?.value
  const showFeaturedImageOnMobile = landingPageData.show_kv_mobile?.value

  let featuredImageClass = ''

  if (!showFeaturedImageOnDesktop && !showFeaturedImageOnMobile) {
    featuredImageClass = 'd-none'
  } else if (!showFeaturedImageOnDesktop) {
    featuredImageClass = 'd-lg-none'
  } else if (!showFeaturedImageOnMobile) {
    featuredImageClass = 'd-none d-lg-block'
  }

  let metaDescription = ''

  if (landingPageData.meta_description) {
    metaDescription = landingPageData.meta_description.value
  }

  let metaKeywords = ''

  if (landingPageData.meta_keywords) {
    metaKeywords = landingPageData.meta_keywords.value
  }

  const seoMeta = [{ name: 'keywords', content: metaKeywords }]

  const schemaOrg = (
    <>
      <meta content={featuredImgUrl} itemProp="image" />
    </>
  )

  let robots = 'noindex, follow'

  if (env.getSeo() == '1') {
    robots = page === 1 ? 'index, follow' : 'noindex, follow'
  }

  // Canoncial
  const canonicals: any = []

  canonicals.push(
    <link
      href={env.getBaseUrl() + options.pageContext.base_url}
      key="canonical-landingpage"
      rel="canonical"
    />,
  )

  buildAlternativeUrlsForLps(
    options.pageContext.base_url,
    landingPageCrossUrls,
  ).map((alternateUrl: AlternateUrl) => {
    canonicals.push(
      <link
        href={alternateUrl.url}
        hrefLang={alternateUrl.locale}
        key={'alternate-' + alternateUrl.url}
        rel="alternate"
      />,
    )
  })
  const kvCta = landingPageData.kv_cta
    ? landingPageData.kv_cta.value
    : undefined
  const kvHeadline = landingPageData.kv_headline
    ? landingPageData.kv_headline.value
    : undefined

  const reviewsFetched = options.pageContext.reviews
  const reviews = reviewsFetched?.items
    ? TransformData(reviewsFetched.items)
    : []

  const showKeyVisual =
    (showFeaturedImageOnDesktop || showFeaturedImageOnMobile) && featuredImgUrl
  const showReviews = ratingsData.showRatings && reviews &&
    reviews.length > 0 && ratingsData.rating

  return (
    <Layout>
      <Helmet>
        <meta content={robots} name="robots" />
        {canonicals.map((canonical: any) => canonical)}
      </Helmet>
      {setEnvironmentVariable('pageType', 'landingpage')}
      {pushTmEvent('artboxone-landingpage-loaded')}

      <SEO description={metaDescription} meta={seoMeta} title={seoTitle} />
      {schemaOrg}
      <Breadcrumb layers={breadcrumbLayers} />

      <div className="container">
        {showKeyVisual && (
          <FeaturedImage
            className={featuredImageClass}
            imageTitle={featuredImgTitle}
            imageUrl={featuredImgUrl}
            kvCta={kvCta}
            kvHeadline={kvHeadline}
            kvRatingClick={handleKVRatingClick}
            ratingsData={ratingsData}
          />
        )}
        {teaserHtml && (
          <div className="row">
            <div className="col teaser">
                {teaserHtml.map((componentData: any, index: number) => {
                  const teaserComponent = componentData.elem

                  if (typeof teaserComponent === 'string') {
                    const signature = getHash({ teaserComponent })

                    return(
                      <div
                        dangerouslySetInnerHTML={{
                          __html: teaserComponent
                        }}
                        key={`component-teaser-${signature}-${index}`}
                      />
                    )
                  } else {
                    return teaserComponent
                  }
                })}
            </div>
          </div>
        )}
        <Edit cockpitId={landingPageData.cockpitId} type="Landingpages" />
      </div>

      <div className="container landingpage">
        {html.map((componentData: any, index: number) => {
          const component = componentData.elem
          const children = componentData.children

          if (component.length == 1 || typeof component === 'string') {
            const signature = getHash({ component })

            return (
              <div
                className="parsedElement"
                key={`component-${signature}-${index}`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: component,
                  }}
                />
                {children}
              </div>
            )
          } else {
            return component
          }
        })}
      </div>
      {showReviews && (
        <div className="container customer-reviews">
          <CustomerReviews
            aggregateRatings={ratingsData.aggregateRatings}
            ratings={ratingsData.rating}
            reviews={reviews}
          />
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query (
    $teaser: String
    $description: String!
    $landingpage_id: String
    $cockpit_id: String
  ) {
    allMarkdownRemark(filter: { id: { in: [$teaser, $description] } }) {
      nodes {
        id
        html
      }
    }

    allCockpitLandingpages(filter: { id: { eq: $landingpage_id } }) {
      nodes {
        cockpitId
        meta_description {
          value
        }
        meta_keywords {
          value
        }
        featured_image_url {
          value
        }
        featured_image_alt {
          value
        }
        pagetitle {
          value
        }
        show_kv_desktop {
          value
        }
        show_kv_mobile {
          value
        }
        show_ratings {
          value
        }
        kv_cta {
          value
        }
        kv_headline {
          value
        }
      }
    }

    allLandingpageUrl(filter: { cockpitId: { eq: $cockpit_id } }) {
      nodes {
        cockpitId
        urls {
          ch
          de
          en
          da
        }
      }
    }
  }
`

export default withUrlSlugs(Landingpage)
