import {env} from '../../../environment'

export const fetchNewsletterData = (options: any) => {
  let country = env.getCountry()

  if (env.getLocale() == 'en-DE') {
    country = 'int'
  }

  let url =
    env.getServiceHost() +
    'order/newsletter?mail=' +
    encodeURIComponent(options.mailAddress) +
    '&mode=' +
    encodeURIComponent(options.mode) +
    '&country=' +
    country

  return fetch(url).then((response) => response.json())
}
