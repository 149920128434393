import { CartItem, ProductMaterial } from '../../types'
import { useProductsByIdData } from '../../lib/products_by_ids_hooks'
import { mapSlug2InternalKey, parseHashUrl } from '../../utils/UrlUtils'
import { calculateCartItem } from '../../utils/CartItemUtils'

const getMotiveIdFromSku = (sku: string) => {
  if (typeof sku != `undefined`) {
    const matches = sku.match(/([0-9]+)-(.+)/)
    if (matches != null && typeof matches[2] != 'undefined') {
      return parseInt(matches[1])
    }
  }

  return 0
}

export const extractSub = (elements: any[]) => {
  let result: any = []

  elements.map((elem: any) => {
    if (typeof elem === 'string') {
      result.push({ elem: elem, children: [] })
    } else if (typeof elem.length !== 'undefined') {
      extractSub(elem).map((e: any) => {
        result.push(e)
      })
    } else {
      if (
        typeof elem.props !== 'undefined' &&
        typeof elem.props['fullWidth'] !== 'undefined' &&
        elem.props['fullWidth'] == false
      ) {
        let i = result.length - 1
        result[i].children.push(elem)
      } else {
        result.push({ elem: elem, children: [] })
      }
    }
  })

  return result
}

export const getOptionsFromUrl = (
  materialsData: ProductMaterial[],
  location: any,
  sku: string,
  materialSlug: string,
) => {
  const motiveId = getMotiveIdFromSku(sku)
  const material = materialSlug
  const { variant, ratio } = parseHashUrl(location.hash)
  const materialIId = parseInt(mapSlug2InternalKey(variant))
  const currentMaterial = materialsData.find((md) => md.iid == materialIId)
  const products = useProductsByIdData([
    {
      material: currentMaterial!.material,
      motiveId,
      ratio: parseInt(ratio),
    },
  ])
  const cartItem: CartItem = {
    motiveId,
    material: currentMaterial!.material,
    materialIId: materialIId,
    count: 1,
    inserted: 0,
    ratio: parseInt(ratio),
  }

  return { material, motiveId, products, cartItem }
}

export const getCalculatedCartItem = (
  cartItem: CartItem,
  materialsData: any,
  products: any,
) => {
  if (products) {
    return calculateCartItem(cartItem, materialsData, products)
      .calculatedCartItem
  }

  return undefined
}
