import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export interface ExternalImageProps {
  src: string
  title: string
}

const ExternalImage = (props: ExternalImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            id
            url

            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  if (props.src === null) {
    return <></>
  }

  const image = data.images.edges.find((n: any) => {
    return n.node.url === props.src
  })

  if (image === undefined) {
    console.warn('Missing image "' + props.src + '"')
    return <></>
  }

  const i = getImage(image.node.childImageSharp)

  if (typeof i == 'undefined') {
    return <></>
  } else {
    return <GatsbyImage image={i} alt={props.title} title={props.title} />
  }
}

export default ExternalImage
