import {env} from '../../../environment'

export const fetchCategories = () => {
  let url =
    env.getServiceHost() +
    'products/categories?version=2&language=' +
    env.getLanguage()

  return fetch(url).then(response => response.json())
}
