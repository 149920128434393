import React from 'react'
import { withUrlSlugs } from '../generic/withUrlSlugs'
import ProductPreview from '../product/Preview'
import { getHash } from '../../utils/Crypto'
import LandingPageMotifListSlider from './LandingPageMotifListSlider'

const LandingPageMotifList = (options: any) => {
  const sliderMode = options.slider === true

  const display = options.display.display
  display.forEach((dis: any) => {
    dis.signature = getHash(dis)
  });

 if (!sliderMode) {
    return (
      <div className="container">
        <div className="row">
          {display.map((p: any) => (
            <div className="col-6 col-sm-4" key={p.signature}>
              <ProductPreview
                productData={p.productData}
                materials={p.materialData}
                material={p.material}
              />
            </div>
          ))}
        </div>
      </div>
    )
  } else {
    return <LandingPageMotifListSlider display={display} />
  }
}

export default withUrlSlugs(LandingPageMotifList)
