import React from 'react'

import { useMaterialsData } from '../../lib/materials_hook'
import LinearProgress from '@mui/material/LinearProgress'

const BuyBox = (options: any) => {
  // let materialsData = useMaterialsData()

  console.log(options)
  let i = 0
  let page = parseInt(options.page) || 1

  // let bundleData = useBundleData({ bundleId: options.bundleId, page: page })

  // if (typeof bundleData === 'undefined') {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <LinearProgress />
          </div>
        </div>
      </div>
    </>
  )
  // } else {
  //   let pageCount = bundleData['pageCount']
  //   let productsData: ProductData[] = bundleData['result']

  //   // bundleData['result'].map((pd: ProductData) => {
  //   //   if (pd.materialgroup == 'cup') {
  //   //     pd.materialgroup = 'cups'
  //   //   }
  //   //   productsData.push(pd)
  //   // })

  //   let paginationProps: PaginationProps = {
  //     template: options.base_url + '/%d',
  //     currentPage: page,
  //     pageCount: pageCount,
  //   }

  //   return (
  //     <>
  //       <div className="row">
  //         <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
  //           <Pagination pagination={paginationProps} />
  //         </div>
  //       </div>
  //       <div className="row">
  //         {productsData.map((productData: ProductData, i) => (
  //           <div className="col-6 col-sm-4" key={'product_preview_' + i}>
  //             <ProductPreview
  //               productData={productData}
  //               materials={materialsData.filter(
  //                 (materialData: ProductMaterial) => {
  //                   return isConnectedMaterialBundle(
  //                     materialData,
  //                     '' + productData.materialgroup,
  //                   )
  //                 },
  //               )}
  //               material={'' + productData.materialgroup}
  //             />
  //           </div>
  //         ))}
  //       </div>
  //       <div className="row">
  //         <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
  //           <Pagination pagination={paginationProps} />
  //         </div>
  //       </div>
  //     </>
  //   )
  // }
}

export default BuyBox
