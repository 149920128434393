import { getSlugs, mapInternalKey2Slug } from './UrlUtils'
import { useCategoriesData } from '../lib/products/categories_hook'
import { useRatiosData } from '../lib/products/ratios_hook'
import { useStylesData } from '../lib/products/styles_hook'
import { useColorsData } from '../lib/products/colors_hook'
import { useArtistsData } from '../lib/artists_hook'

export const parseFilterUrl = (filter: string) => {
  const urlSlugAll = mapInternalKey2Slug('all')

  const result: any = {
    categories: [],
    colors: [],
    ratios: [],
    artists: [],
    styles: [],
    ready: false,
  }

  const categorySlugs: string[] = getSlugs(useCategoriesData())
  const colorSlugs: string[] = getSlugs(useColorsData())
  const ratioSlugs: string[] = getSlugs(useRatiosData())
  const artistSlugs: string[] = getSlugs(useArtistsData())
  const stylesSlugs: string[] = getSlugs(useStylesData())

  if (
    categorySlugs.length == 0 ||
    colorSlugs.length == 0 ||
    ratioSlugs.length == 0 ||
    artistSlugs.length == 0 ||
    artistSlugs.length == 0 ||
    stylesSlugs.length == 0
  ) {
    return result
  } else {
    result.ready = true
  }

  result.categories = filter
    .split('_')
    .map(elem => '' + elem)
    .filter(elem => '' + elem !== urlSlugAll)

  if (categorySlugs.length > 0) {
    if (result.categories.length > 0) {
      // Filter to only available categories
      result.colors = result.categories.filter((elem: string) => {
        return colorSlugs.indexOf(elem) >= 0
      })
      result.ratios = result.categories.filter((elem: string) => {
        return ratioSlugs.indexOf(elem) >= 0
      })
      result.artists = result.categories.filter((elem: string) => {
        return artistSlugs.indexOf(elem) >= 0
      })
      result.styles = result.categories.filter((elem: string) => {
        return stylesSlugs.indexOf(elem) >= 0
      })
      result.categories = result.categories.filter((elem: string) => {
        return (
          categorySlugs.indexOf(elem) >= 0 ||
          ['customizable'].indexOf(elem) >= 0
        )
      })
    }
  }

  return result
}
