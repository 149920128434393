import { AggregateRatingsProps } from '../../../types'

const getOverallRatings = (aggregateRatings: AggregateRatingsProps) => {
  const overallReviews = aggregateRatings?.overall?.distribution

  let overallRatings = overallReviews ? [
    overallReviews.fiveStars,
    overallReviews.fourStars,
    overallReviews.threeStars,
    overallReviews.twoStars,
    overallReviews.oneStar,
  ] : []

  return overallRatings
}

export default getOverallRatings
