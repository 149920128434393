import React from 'react'
import Button from '@mui/material/Button'

export interface HrefButtonProps {
  href: string
  fullWidth: boolean
  text: any
}

export default function HrefButton(props: HrefButtonProps) {
  let href = props.href

  // if (props.href.substr(0, 1) != '/' && props.href.substr(0, 4)! + 'http') {
  //   href = 'https://' + href
  // }

  return (
    <Button
      className="cta_lp_button"
      variant="contained"
      color="primary"
      href={href}
      fullWidth={props.fullWidth}
    >
      {props.text}
    </Button>
  )
}
