import { fetchColors } from './colors_api'
import { useState, useEffect } from 'react'
import { Color } from '../../types'
import {env} from '../../../environment'
import {
  localStorage_setItem,
  localStorage_getItem,
} from '../../utils/LocalStorageHelper'

export const useColorsData = () => {
  const [colors, setColors] = useState([])

  const localStorageId: string =
    process.env.STORAGE_PREFIX +
    '_color_' +
    env.getLanguage() +
    '_' +
    process.env.STORAGE_SUFFIX

  useEffect(() => {
    let colorsFromLocalStorage = localStorage_getItem(localStorageId)
    if (typeof colorsFromLocalStorage == 'string') {
      setColors(JSON.parse(colorsFromLocalStorage))
    } else {
      fetchColors().then(response => {
        setColors(Object.values(response.data))
        localStorage_setItem(
          localStorageId,
          JSON.stringify(Object.values(response.data)),
        )
      })
    }
  }, [env.getLanguage()])

  return colors
}
