import React from 'react'
import { PaginationProps, ProductData } from '../../types'
import { useProductsData } from '../../lib/products_hooks'
import Pagination from '../generic/Pagination'
import ProductPreview from '../product/Preview'
import { getHash } from '../../utils/Crypto'

const LandingPagePuesInner = (options: any) => {
  let productsData: ProductData[] = useProductsData(options.productsDataOptions)
  let productsCount: any = useProductsData(options.productsCountOptions)

  let paginationProps: PaginationProps = {
    template: options.base_url + '/%d',
    currentPage: parseInt('' + options.page),
    pageCount: Math.ceil(productsCount?.count / 60),
  }

  let signature = getHash(
      JSON.stringify(options.productsDataOptions) +
        JSON.stringify(paginationProps),
    )

  return (
    <div key={signature}>
      <div className="row">
        <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
          {options.hidePagination != true && (
            <Pagination pagination={paginationProps} />
          )}
        </div>
      </div>
      <div className="row">
        {productsData.map((productData: ProductData, i) => (
          <div
            className="col-6 col-sm-4"
            key={signature + 'product_preview_' + i}
          >
            <ProductPreview
              productData={productData}
              materials={options.materialsData}
              material={'' + options.material}
              variant={options.variant}
            />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
          {options.hidePagination != true && (
            <Pagination pagination={paginationProps} />
          )}
        </div>
      </div>
    </div>
  )
}

export default LandingPagePuesInner
