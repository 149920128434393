import { fetchCategories } from './categories_api'
import { useState, useEffect } from 'react'
import { Category } from '../../types'
import {env} from '../../../environment'
import {
  localStorage_getItem,
  localStorage_setItem,
} from '../../utils/LocalStorageHelper'

export const useCategoriesData = () => {
  const [categories, setCategories] = useState([])

  const localeStorageId: string =
    process.env.STORAGE_PREFIX +
    '_categories_' +
    env.getLanguage() +
    '_' +
    process.env.STORAGE_SUFFIX

  useEffect(() => {
    let categoriesFromLocalStorage = localStorage_getItem(localeStorageId)
    if (typeof categoriesFromLocalStorage == 'string') {
      setCategories(JSON.parse(categoriesFromLocalStorage))
    } else {
      fetchCategories().then(response => {
        setCategories(Object.values(response.data))
        localStorage_setItem(
          localeStorageId,
          JSON.stringify(Object.values(response.data)),
        )
      })
    }
  }, [env.getLanguage()])

  return categories
}
