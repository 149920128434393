import { fetchStyles } from './styles_api'
import { useState, useEffect } from 'react'
import {env} from '../../../environment'
import {
  localStorage_getItem,
  localStorage_setItem,
} from '../../utils/LocalStorageHelper'

export const useStylesData = () => {
  const [styles, setStyles] = useState([])

  const localeStorageId: string =
    process.env.STORAGE_PREFIX +
    '_styles_' +
    env.getLanguage() +
    '_' +
    process.env.STORAGE_SUFFIX

  useEffect(() => {
    let categoriesFromLocalStorage = localStorage_getItem(localeStorageId)
    if (typeof categoriesFromLocalStorage == 'string') {
      setStyles(JSON.parse(categoriesFromLocalStorage))
    } else {
      fetchStyles().then(response => {
        setStyles(Object.values(response.data))
        localStorage_setItem(
          localeStorageId,
          JSON.stringify(Object.values(response.data)),
        )
      })
    }
  }, [env.getLanguage()])

  return styles
}
